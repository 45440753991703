.card {
    vertical-align: top;
    display: inline-block;
    text-decoration: none;
    background-color:#f2f2f2;
    margin-top:0px;
    /* margin-bottom:10px; */
    padding: 0;
    width:100%;
    white-space: normal;
}

.card > div:first-child { border-radius:3px 3px 0 0; }

.link{
  color: inherit;
  text-decoration: none;
}
.link:hover{
  color: inherit;
  text-decoration: none;
}
.riverHolder {
  vertical-align: top;
  text-decoration: none;
  background-color:#f2f2f2;
  margin-top:0px;
  width:100%;
  height: 100%;
}
.riverContainer{
  height:100%;
  display: flex;
  align-items: center; 
  padding-right: 0px;
}
.row{
  /* background-color:red; */
  width: 100%;
  height:100%;
  display: flex;
  align-items: center; 
  justify-content: center;
}
.infoRow{
  padding:12px 0 12px 8px;
}
.riverRow {
  background-color: #fff;
  height:50%;
  /* margin-bottom: 10px; */
  padding: 0px;
  display: flex; 
  justify-content: center;
  align-items: center;
  border:1px solid #f2f2f2;  
}
.riverRowLabelHolder{
  /* background-color:#9400D3; */
  padding: 0;
  height: 100%;
}
.riverRowLabel{
  background-color:#923f93;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.riverRowContent{
  padding-left: 10px;
  flex: 1;
}
.riverRowTitle{
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  margin-top: 20px;
}
.riverRowDate{
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  color: #666666;
}
.riverRowExcerpt{
  font-size: 15px;
  line-height: 20px;
  color:#666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  letter-spacing: 2px;
}
.riverRowTag{
  padding-top: 5px;
  font-size: 15px;
  line-height: 25px;
  text-align: right;
  color: #3392BC;
}
.playBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.colum{
  display: flex;
  padding:0 0 0 0;
  cursor: pointer;
}

.fullColum{
  padding:0;
}

.titleColum{
  min-height:50px;
  padding:12px 0 12px 0;
}

.bottomArea{
  min-height: 50px;
}

.title{
  color:#fff;
  font-size: 18px;
  line-height: 25px;
  height: 60px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-top: -71px;
  background: linear-gradient(0deg, rgba(66,66,66,0.8) 0%, rgba(66,66,66,0.5) 70%, rgba(66,66,66,0) 100%);
  padding:5px 10px 5px 10px;
}
.card:hover .title {
  background: linear-gradient(0deg, rgba(44,44,44,0.8) 0%, rgba(44,44,44,0.5) 80%, rgba(44,44,44,0) 100%);
}

.card img {
  transform: scale(1);
  transition:transform 0.5s;
}

.card:hover img {
  transform: scale(1.1);
}

.excerpt{
  font-size: 15px;
  line-height: 20px;
  height: 40px;
  margin-top: 5px;
  color:#666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  padding: 0 18px 0 8px;
  letter-spacing: 2px;
}

.tag{
  border:1px solid #0077AB;
  border-radius: 2px;
  font-size: 10px;
  color:#0077AB;
  width: 30px;
  text-align: center;
  line-height: 16px;
}

.date{
  font-size: 15px;
  line-height: 16px;
  color:#666666;
  font-weight: 500;
}

.range{
  width:100%;
  overflow: hidden;
  -webkit-appearance: none;
  border-radius: 4px;
  height: 5px;
  background:#DBEAF9;
}

.range:hover{
  border-radius: 4px;
  height: 8px;
  opacity: .8;
  transition-duration: .3s;
  transition-delay: .1s;
}

/* will work */ 
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1px;
  height: 8px;
  cursor: pointer;
  background-color: #0077AB;
  box-shadow: -1200px 0 0 1200px #0077AB;
  border-radius: 50%;
}

.range::-webkit-slider-thumb:hover {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  cursor: pointer;
  background-color:#4573a1;
  box-shadow: -1200px 0 0 1200px #0077AB;
  border-radius: 0%;
}

.range::-moz-range-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: #0077AB;
  box-shadow: -120px 0 0 1200px #0077AB;
  border-radius: 50%;
}

.center{
  text-align: center;
}

.category{
  background-color: #923f93;
  text-align: center;
  color: #fff;
  float: right;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 600px){
  .excerpt{
    font-size: 18px;
    line-height: 22px;
    height: 44px;
    margin-top: 5px;
    color:#666666;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    padding: 0 18px 0 8px;
    letter-spacing: 2px;
  }
  .date{
    font-size: 16px;
    line-height: 16px;
    color:#666666;
    font-weight: 500;
  }
  .riverRowTitle{
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-top: 20px;
  }
  .riverRowDate{
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    color: #666666;
  }
  .riverRowExcerpt{
    font-size: 18px;
    line-height: 22px;
    color:#666666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    letter-spacing: 2px;
  }
}
  .fullWidth{
    width: 100%;
    text-align: center;
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 999;
    color:#000;
    margin-top: 20px;
  }
  
  .inherit{
    color:inherit;
  }

  .inherit:hover{
    cursor: pointer;
  }

  .innerHolder{
    padding:30px;
  }

  .alignCenter{
    /* text-align: center; */
    text-align: left;
    line-height: 30px;
    /* background-color: #fab667; */
  }

  .floatLeft{
    /* display: inline-block;  */
    text-align: left;
  }

  .footerBottom{
    background-color: #0077AB !important;
    padding:10px 0 10px 0; 
    color: #ffffff;
  }

  .title{
    font-size: 150%;
    font-weight: 500;
    margin: 15px 0  ;
  }

  a.inherit { color:#000000; }
  a.inherit:hover, a.inherit:active { color:#0077AB; text-decoration: none; }

  .row img { border-radius:10px; }
  
  .marT{
    margin-top: 10px;
  }
  
  .inherit{
    text-decoration: none;
  }

  .row{
    padding: 0;
    margin: 15px 0;
  }

  .rowTitle{
    font-size: 16px;
    line-height: 18px;
    padding: 0 8px;
  }

  .rowTxt{
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    padding: 4px 8px;
    color:#555555;
  }

  .social_link a { font-size:20px; color:#666666;  margin-right:10px; }

  .offeringImg{
    margin-top: 10px;
  }

  .viewCountHolder{
    display: inline-block;
  }
  .viewCountTitle{
    color: #000;
    font-size: 24px;
    font-weight: 500;
    padding: 15px 0 10px 0;
  }
  .viewCountTag{
    /* border: 1px solid #0077AB; */
    color:#535353;
    line-height: 22px;
  }
  .viewCount{
    color: #424242;
    font-weight: 600;
  }

  @media screen and (min-width: 1024px) and (max-width: 1024px) and (max-height: 1366px) {
    .footerBottom{
      padding:20px 0px 30px 0px !important; 
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px){
    .rowTxt{
      padding-top:3px;
      padding-left: 8px;
      font-size: 15px;
      line-height:18px;
      height:39px;
    }
  }  

  @media screen and (max-width: 600px){
    .leftRowTitle{
      font-size: 18px;
    }
    .alignCenter{
      text-align: left;
      line-height: 30px;
      margin-top: 30px;
    }
    .rowTitle{
      padding-left: 10px;
    }
    .rowTxt{
      padding-top:5px;
      padding-left: 10px;
      font-size: 15px;
    }
    .footerBottom{ font-size:12px; }
  }
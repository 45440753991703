.full{
  width: 100%;
  padding: 0;
  margin:0;
}

.fullWidth0{
  width: 100%;
  background-color: #fcdeba;
  padding: 30px 0 30px 0;
}
.fullWidth{
  width: 100%;
  background-color: #f4e9c1;
  padding: 30px 0 30px 0;
  margin-top: 20px;
}
.fullWidth2{
  width: 100%;
  background-color: #cee0e6;
  padding: 30px 0 30px 0;
}
.fullWidth3{
  width: 100%;
  background-color: #fef4e8;
  padding: 30px 0 30px 0;
}
.videoResponsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.videoResponsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.header{
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 10px 0;
}

.img{
  border-radius: 3px;
}

.subtitle{
  padding: 0 10px 0 20px;
  color:#666666
}

.promotion_bttm_container { width: 100%; background:#f2f2f2; }
.promotion_bttm { width: 100%; max-width:2000px; margin:0 auto; height:12vw; max-height:200px; white-space: nowrap; overflow:hidden; }
.bttm_promotion_title { position:absolute; left:max(0 , 50vw - 1000px); color:#ffffff; padding:10px 15px; font-weight:800; background:#4a8db9; box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); z-index:500; }
.bttm_promotion_title svg { font-size:20px; color:#dddddd; margin-right:10px; }
.bttm_promotion_title svg:hover { color:#ffffff; }
.znse_promotion_title { position:absolute; left:max(0 , 50vw - 1000px); color:#ffffff; padding:10px 15px; font-weight:800; background:#B71568; box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); z-index:500; }
.znse_promotion_title svg { font-size:20px; color:#dddddd; margin-right:10px; }
.znse_promotion_title svg:hover { color:#ffffff; }
.bttm_item { position:relative; display:inline-block; width:min(25vw , 500px); height:100%; margin:0; cursor:pointer; overflow:hidden; background: #464646; background: linear-gradient(50deg, rgba(20,20,20,1) 0%, rgba(123,179,215,1) 100%); }
.bttm_item > div { position:absolute; bottom:0; left:0; width:100%; color:#ffffff; font-size:15px; white-space:break-spaces; text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); padding:5px 10px; }
.bttm_item img { position:absolute; top:50%; transform:translateY(-50%) scale(1); width:100%; opacity:0.7; transition:transform 0.5s, opacity 0.5s; }
.bttm_item:hover img { transform:translateY(-50%) scale(1.1); opacity:1; }

@media screen and (max-width: 992px) {

  .promotion_bttm { height:24vw; min-height: 200px; }
  .bttm_item { min-width:360px; }

  .header{
    font-size: 22px;
    font-weight: 500;
    margin:20px 0 0px 0 !important;
  }
  .full{
    padding: 0 3px 10px 3px;
  }
  .zeroPadding{
    padding: 0;
    margin: 0;
  }
  .videoResponsive {
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width: 600px) {
  .header{
    font-size: 22px;
    font-weight: 500;
    margin:20px 0 0px 0 !important;
  }
  .zeroPadding{
    padding: 0;
    margin: 0;
  }
  .full{
    padding: 0 3px 10px 3px;
  }
  .fullWidth0{
    width: 100%;
    background-color: #fcdeba;
    padding: 0px 0 0px 0;
  }
  .fullWidth{
    width: 100%;
    background-color: #f4e9c1;
    padding: 0px 0 0px 0;
  }
  .fullWidth2{
    width: 100%;
    background-color: #e8daeb;
    padding: 0px 0 0px 0;
  }
  .fullWidth3{
    width: 100%;
    background-color: #fde8d0;
    padding: 0px 0 0px 0;
  }
  .videoResponsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin: 0 0 30px 0;
  }
  .subtitle{
    padding: 20px 10px 20px 10px;
    color:#666666
  }

  .bttm_item > div { position:absolute; bottom:0; left:0; width:100%; color:#ffffff; font-size:15px; text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); padding:5px 10px; }
  .bttm_item img { position:absolute; top:50%; transform:translateY(-50%) scale(1); width:100%; opacity:0.7; transition:transform 0.5s, opacity 0.5s; }

}

@media screen and (min-width: 1024px) and (max-width: 1024px) and (max-height: 1366px) {
  .full{
    padding: 0;
    margin: 0;
  }
  .header{
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 px 3px;
    margin:0
  }
  .zeroPadding{
    margin:0 !important;
    padding:0 2px 0 2px
  }
  .videoResponsive {
    margin: 0 0 30px 0;
  }
}



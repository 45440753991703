.colum{
  display: flex;
  padding:0 10px 0 0;
}

.fullColum{
  padding:0;
}

.titleColum{
  min-height:50px;
  padding:12px;
}

.bottomArea{
  min-height: 50px;
}

.title{
  color:#1a1e28;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
}

.tag{
  border:1px solid #0077AB;
  border-radius: 2px;
  font-size: 10px;
  color:#0077AB;
  width: 30px;
  text-align: center;
  line-height: 16px;
}

.date{
  font-size: 16px;
  line-height: 16px;
  color:#999999;
  margin-left: 10px;
}
.timeHolder{
  width:98%;
  line-height: 14px;
  height: 14px;
  padding: 0 0 0 0;
}
.timeContainer{
  display:flex;
  justify-content:space-between;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; 
  line-height:16px
}
.timeCurrent{
  font-variant-numeric: tabular-nums;
  color:#0077AB;
  font-size: 14px;
  display: inline-block; 
  padding-left: 10px;
  text-align: left;
  width: 50%;
}
.timeTotal{
  font-variant-numeric: tabular-nums;
  color:#0077AB;
  font-size: 14px;
  display: inline-block; 
  padding-right: 10px;
  text-align: right;
  width: 50%;
}
.rangeHolder{
  display: inline;
}
.range{
  -webkit-appearance: none;
  -moz-apperance: none;
  width: calc(100% - 20px);
  height: 5px;
  border-radius: 5px;
  outline: 0;
  background-color: #fff;
}
.range:hover{
  cursor: pointer;
}
.rangeContainer{
  text-align: center;
  margin:2px auto 3px auto !important;
  padding-top:0px !important;
  line-height:50%;
}
/* thumb */ 
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  transition: all .1s;
  background-color:#0077AB;
  border: 1px solid #0077AB;
  /* margin-top: -5px; */
  
}
.range::-webkit-slider-thumb:hover {
  width: 16px;
  height: 16px;
}

/* All the same stuff for Firefox */
.range::-moz-range-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  transition: all .1s;
  background-color:#0077AB;
  border: 1px solid #0077AB;
}

/* All the same stuff for IE */
.range::-ms-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  transition: all .1s;
  background-color:#0077AB;
  border: 1px solid #0077AB;
}

.center{
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
}

.player{
  width: 100%;
  /* margin-top: 10px; */
  padding:0;
}

.btnHolder{
  width:98%;
  padding: 0 10px 0 10px;
}

.shareBtn{
  text-align: center;
  width: 50px;
  border:1px solid #666666;
  color: #666666;
  padding: 2px 0;
  font-size: 14px;
  border-radius: 20px;
  margin-left: 10px;
  float: left;
  cursor: pointer;
}
.shareBtn:hover{
  background-color: #666666;
  color: #fff;
}
.playerWrapper{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color:rgba(239,239,239,.9);
  box-shadow: rgb(0 33 155 / 12%) 0px 4px 5px -2px;
  padding: 0 0 0 0;
  z-index: 1000;
  /* border-top: 2px solid #CCE4EE; */
}

.playStopBtnBuffering {
  cursor: pointer;
  display: inline-block;
  opacity: 0.5;
}
.playerBtn{
  cursor: pointer;
  display: inline-block;
  padding: 0 10px 0 10px;
}
.playerBtn:hover{
  transform: scale(1.2);
  /* font-size:6em; */
  transition: .3s ease-out;
}
.playerBtnOff{
  font-size: 26px;
  color:#cacaca;
}
.playerBtnOn{
  font-size: 26px;
  color:#0077AB;
}
.playBtn{
  display:inline-block;
}
.repeatOne {
  position:absolute;
  float:left;
  font-size: 12px;
  margin-top: 4px;
  margin-left: -1px;
  font-weight:bold;
  color:#0077AB;
}
.redoBtnOn{
  font-size: 24px;
  margin:0 0 0 -9px;
  color:#0077AB;
}
.redoBtnOff{
  font-size: 24px;
  margin:0 0px 0 0;
  color:#cacaca !important;
}
.redoBtnSlash{
  margin:0 -27px 0 -12px;
  font-size: 24px;
  color:#cacaca !important;
}

.nextBtn, .autoBtn{
  cursor: pointer;
  margin-left: 25px;
  display: inline-block;
}
.prevBtn, .repeatBtn{
  cursor: pointer;
  margin-right: 25px;
  display: inline-block;
}
.playerBtnOff:hover, .playerBtnOn:hover, .autoBtn:hover{
  color: #4ca0c0 !important;
}
.actionBtn{
  font-size: 20px;
  color:#0077AB;
}
.noBtn{
  width: 42px;
  height: 1px;
  display:inline-block;
}
/* .playerBtnOff:hover, .playerBtnOn:hover, .autoBtn:hover{
  color: #0077AB !important;
} */

@media screen and (max-width: 600px){

  .playerWrapper{
    background-color:rgba(239,239,239,.9);
    padding-bottom: 5px;
  }

  .timeCurrent{
    font-variant-numeric: tabular-nums;
    color:#0077AB;
    font-size: 14px;
    display: inline-block; 
    padding-left: 10px;
    text-align: left;
    width: 50%;
  }
  .timeTotal{
    font-variant-numeric: tabular-nums;
    color:#0077AB;
    font-size: 14px;
    display: inline-block; 
    padding-right: 10px;
    text-align: right;
    width: 50%;
  }
  .range{
    width: calc(100% - 20px);
    height: 5px;
    border-radius: 5px;
    outline: 0;
    background-color: #fff;
  }
  .center{
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .playerBtn{
    padding: 0 8px 0 8px;
  }
  .playerBtnOff:hover, .playerBtnOn:hover, .autoBtn:hover{
    color: #0077AB !important;
  }
}
@media screen and (max-width: 900px) and (min-height: 1024px) and (max-height: 1024px) {

  .playerBtnOff:hover, .playerBtnOn:hover, .autoBtn:hover{
    color: #0077AB !important;
  }

  .center{
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }
    
}

@media screen and (width: 820px) and (height: 1180px) {
  .center{
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) and (max-height: 1366px) {
  .playerWrapper{
    bottom: 0;
  }
  .playerBtnOff:hover, .playerBtnOn:hover, .autoBtn:hover{
    color: #0077AB !important;
  }
  .center{
    margin-bottom: 20px;
  }

}

@media only screen and (min-width: 834px) and (orientation: landscape){ 
/* iPad pro landscape */
  .btnHolder{
    width:98%;
    padding: 0 10px 10px 10px;
  }
}


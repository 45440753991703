  .fullWidth{
    width: 100%;
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .inherit{
    color:#666666 !important;
    padding: 0 10px 0 10px;
  }
  
  .inherit:hover{
    cursor: pointer;
  }
  
  .userName{
    font-size: 14px;
  }

  .signin{
    color:#0077AB;
    margin-left: 10px;
  }
  .innerHolder:hover{
    cursor:inherit
  }
  .signout{
    color:#0077AB;
    margin-left: -5px;
  }

  .signin:hover {
    color:#3392BC;
  }

  .signout:hover {
    color:#3392BC;
  }

  .login:hover{
    cursor: pointer;
  }

  .brand{
    cursor: pointer;
  }
  
  .brandNav{
    background-color: #0077AB;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0 20px;
    padding-top: 5px;
    margin:0;
  }
  
  .navShadow{
    box-shadow: rgb(0 33 155 / 12%) 0px 4px 5px -2px;
  }
  
  .navBar{
    padding: 0;
    background-color: #0077AB;
  }
  .navCollapse{
    background-color:#f2f2f2;
    padding: 8px 5px 5px 0px;
  }
  
  .navbar-light .navbar-toggler {
    color: red;
  }

  .lgNav{
    margin-left: 50px
  }
  .smNav{
    display: none;
  }
  
  .toggleBtn{
    margin-right: 10px;
    margin-left:10px;
  }

  .smNavRow{
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0 0 10px 0;
  }

  .smNavBtn{
    border:1px solid #0077AB;
    color:#0077AB;
    padding: 5px;
    border-radius: 5px;
    width: auto;
    font-size: 14px;
    line-height: 22px;
  }

  .searchIcon{
    font-size: 22px;
    color: #757575;
  }

  .userAvatar{
    width:40px;
    height:40px;
    border-radius: 50%;
    transform:translate(-5px, 0);
  }

  .smfullWidth{
    width: 100%;
    height: 100%;
    text-align: center;
    background-color:#fff;
    z-index: 20000;
    position: fixed; 
    top: 52px;
    left: 0;
    right: 0;
    overflow-y: auto;
    padding-bottom: 100px;
  }

  .title{
    font-size: 140%;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-bottom: 3px;
    color:000;
    border-bottom:3px solid #d7d7d7;
  }

  .itemBtn{
    color: #646464;
    border:1px solid #646464;
    display:inline-block;
    margin: 0 10px 10px 0;
    padding: 2px 5px;
    border-radius: 5px;
  }
  
  .itemBtn:hover{
    background-color: #646464;
    color: #fff;
  }

  .floatLeft{
    text-align: left;
  }

  .smDivider{
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .smSubHeader{
    width:100%;
    background-color:#CCE4EE;
    display:flex;
    align-items:center;
    padding: 10px 15px 10px 15px;
    justify-content: space-between;
  }
    
  .smLoginHolder{
    display:flex;
    align-items:center;
  }
  .smLoginIcon{
    font-size:30px;
    display:inline;
    color:#757575;
  }
  .smLoginBtn{
    font-size:20px;
    color:#757575;
    padding-left: 5px;
    display:inline;
  }
  .smSearchHoder{
    display:flex;
    align-items:center;
  }
  .smSearchBtn{
    font-size:20px;
    color: #757575;
    margin-left: 5px;
  }
  .smSearchIcon{
    font-size: 28px;
    display:inline;
    color:#757575;
  }
  .smATag, .smATag:hover, .smATag:active, .smATag:visited, .smATag:focus {
    text-decoration:none;
  }
  .smLoginBtn:active, .smLoginBtn:hover, .smSearchBtn:active, .smSearchBtn:hover{
    color:#0077AB;
  }

  @media screen and (max-width: 600px){
    .inherit {margin-left:30px}
    .signin { margin-left: 40px }
    .signout { margin-left: 35px }
    .brandNav{ padding: 10px 2px 5px 8px; }
    .lgNav{display: none !important}
    .smNav{display: block !important}
    .smSubHeader{ padding: 0px 15px 2px 15px;}
  }
  
  @media only screen and (min-width: 601px) and (max-width: 991px) {
    .authWrapper { margin-top: 30%}
    .inherit { margin-left:30px }
    .signin { margin-left: 40px }
    .signout { margin-left: 35px }
    .brandNav{ padding: 15px 2px 8px 15px }
    .lgNav{display: none !important}
    .smNav{display: block !important}
    .smfullWidth{top: 60px;}
    .smSubHeader{ padding: 2px 15px 4px 15px;}
  }
  
  /* @media only screen and (min-width: 768px) and (max-width: 991px) {
    .authWrapper { margin-top: 30%}
    .inherit { margin-left:30px }
    .signin { margin-left: 40px }
    .signout { margin-left: 35px }
    .brandNav{ padding: 15px 2px 8px 15px }
    .lgNav{display: none !important}
    .smNav{display: block !important}
  } */
  
  @media screen and (min-width: 1024px) and (max-width: 1024px) and (max-height: 1366px) {
    .authWrapper { margin-top: 30%}
    .smSubHeader{
      padding: 10px 15px 10px 15px;
    }
  }
  


.header{
  border-left:4px solid #0077AB;
  padding-left: 12px;
  font-size: 20px;
  line-height: 40px;
  /* margin-left: 6px; */
  /* margin-bottom: 10px;  */
}

.stickyHeader{
  background-color:#fff;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  /* top: 51px; */
  z-index: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
  padding-top:7px;
  padding-bottom:5px;
}

.headerRow{
  margin:5px 0 5px 0;
}
.fullWidth{
  padding:0;
}

.viewMore{
  text-align: right;
  line-height: 40px;
  font-size: 16px;
}

.viewMoreLabel{
  line-height: 40px;
  font-size: 16px;
  color:#666666;
  padding-right: 5px;
}
.viewMoreLabel:hover{
  color:#999999;
}
.holder{
  margin: 20px 0 10px 0;
  width: 100%;
  cursor: pointer;
}
.holder a {
  text-decoration: none;
  color:inherit
}

.moreIcon{
  font-size: 16px;
}
@media screen and (max-width: 600px){
  .header{
    border-left:5px solid #0077AB;
    padding-left: 8px;
    font-size: 22px;
    line-height: 40px;
    margin-left: 10px;
    font-weight: 500;
  }
  .viewMoreLabel{
    line-height: 40px;
    font-size: 16px;
    color:#666666;
  }
  .stickyHeader{
    display: block;
    top: 51px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 991px) {
  .stickyHeader{
    display: block;
    top: 59px;
  }
}

@media screen and (max-width: 900px) {
  .header{
    margin-left:5px;
  }
  .stickyHeader{
    display: block;
    top: 58px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) and (max-height: 1366px) {
  .header{
    margin-left:5px;
  }
  .stickyHeader{
    display: block;
    top: 50px;
  }
}
.maskLayer{
  background-color: black;
  opacity: 0.7;
  position: fixed;
  top: 60px;
  width: 100vw;
  height: 100vh;
}

.hideMe{
  display: none;
}

.showMe{
  display: block;
  background-color: rosybrown;
}

.wrapper{
  max-width: 1200px;
  margin: 0px 100px;
}

.innerHolder{
  padding:10px 30px 30px 30px;
  cursor: pointer;
  background-color:white ;
  position: absolute;
  box-shadow: 0px 20px 20px #00000077;
  width: calc(100vw + 200px);
  left: -100px;
  display: flex;
  justify-content: center;
}

.innerHolder:hover{
  cursor:inherit
}
.closeBtn{
  float: right;
  font-size: 30px;
  cursor: pointer;
}

.alignCenter{
  /* text-align: center; */
  line-height: 30px;
}

.floatLeft{
  /* display: inline-block;  */
  text-align: left;
}

.title{
  font-size: 140%;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 3px;
  color:000;
  /* border-bottom:3px solid #CCE4EE; */
  border-bottom:3px solid #66ADCD;
}

.itemBtn{
  color: #646464;
  background-color: #fff;
  border:1px solid #646464;
  display:inline-block;
  margin: 0 10px 10px 0;
  padding: 2px 5px;
  border-radius: 5px;
}

.itemBtn:hover{
  background-color: #1A6E97;
  color: #fff;
}
.bgColor{
  width: 100%;
  background-color: #fafafa;
}

.notice{
  width: 100%;
  background-color: #F4F9FB;
  padding: 10px 10px;
}

.wrapper {
  margin:0px 20px 0px 20px;
}

.fullWidth {
  padding: 0;
}

.slider{
  padding: 0 5px 0 0; 
  width: 640px
}

.noticeBar{
  padding:10px 5px;
  background-color:#fff;
  text-align:left;
}

.noticeHolder{
  width:100%;
  /* background-color:green; */
  /* line-height:36px;
  height:36px; */
  text-align:left;
}

.noticeContent{
  /* white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: auto;
  line-height:33px;
  height:36px;
  padding:0px 10px;
  border:1px solid #efefef;
  font-size:13px;
  text-align:left;
  width:92.8%;
}

.noticeFlag{
  background-color:#f70d28;
  /* display:inline-block; */
  line-height:38px;
  height:35px;
  width:21px;
  text-align:center;
  color:#fff;
  font-size:8px;
}

.searchIcon{
  background-color:##fff;
  line-height:34px;
  height:36px;
  width:31px;
  text-align:center;
  color:#777777;
  font-size:14px;
  border:1px solid #efefef;
}

.boltIcon{
  color:#fff;
  font-size:11px;
  line-height:36px;
  text-align:center;
}

.rightSide{
  width: 520px !important;
}

.rightTop{
  padding:0;
  background-color: #F39800;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.today{
  font-size: 28px;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  text-align: right;
  letter-spacing: 2px;
}
.year{
  font-size: 32px;
}
.rightBottom .divider1{
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin-bottom: 3px;
  margin-top: 10px;
}
.rightBottom .divider2{
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin-top: 3px;
  margin-bottom: 2px;
}
.block{
  display: block;
}
.weather{
  color:#fff;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
}
.bigDot{
  font-size: 11px;
  color:#fff;
  line-height: 20px;
  padding-left: 8px;
}
.offeringImg{
  text-align: center;
  margin:0;
  margin-top: 8px;
}
.rightBottom{
  padding:30px 10px 3px 10px;
  background-color: #F39800;
}
.churchIcon{
  margin: 0;
  margin-top: 20px;
}
.leftBanner{
  background-color: #a7a7a7;
}

.zionIcon{
  margin-top:70px
}

.viewCount{
  display: inline-block;
  color: #0077AB;
  border-radius: 5px;
  padding: 3px 15px;
  margin-right: 10px;
  font-size: 14px;
}
.viewCountTitle{
  font-size: 16px;
  font-weight: 800;
}
.viewCountNum{
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  margin-top: -10px;
  line-height: 18px;
  letter-spacing: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .slider{
    padding: 0; 
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .offeringImg{
    margin-top: 5px;
  }

  .today{
    font-size: 26px;
    line-height: 28px;
    
  }
  .year{
    font-size: 29px;
  }
  .weather{
    line-height: 16px;
    font-size: 14px;
  }
  .bigDot{
    font-size: 11px;
    line-height: 16px;
    padding-left: 5px;
  }
  .rightBottom{
    padding:20px 5px 3px 5px;
  }

  .offeringImg{
    margin:0;
    margin-top: 0px;
  }

  .churchIcon{
    margin: 0;
    margin-top: 10px;
  }
  .viewCountNum{
    font-size: 14px;
    font-weight: 800;
    margin-top: -18px;
    line-height: 16px;
  }
}

@media screen and (max-width: 600px){
  .slider{
    padding: 0; 
  }
}
.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  height: 340px;
  /* background-color:#efefef; */
}

.card {
  vertical-align: top;
  display: inline-block;
  text-decoration: none;
  background-color:#f2f2f2;
  margin-top:0px;
  width:100%;
  white-space: normal;
  cursor: pointer;
  border-radius: 3px;
  border:1px solid #f2f2f2;
}

.card > div:first-child { border-radius:3px 3px 0 0; }

.img{
  border-radius: 3px;
}

.infoRow{
  padding:10px 0 12px 12px;
}

.colum{
 padding:0 5px 20px 0px;
 margin:0;
}

.colum2{
  padding:0 5px 20px 5px;
  margin:0;
}

.fullColum{
  padding:0;
}

.titleColum{
  min-height:50px;
  padding:12px 0 12px 0;
}

.bottomArea{
  min-height: 50px;
  /* padding:12px; */
}

.title{
  color:#fff;
  font-size: 16px;
  line-height: 23px;
  height: 52px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-top: -64px;
  background: linear-gradient(0deg, rgba(66,66,66,0.8) 0%, rgba(66,66,66,0.5) 70%, rgba(66,66,66,0) 100%);
  width: 100%;
  padding:5px 10px 5px 10px;
}

.card:hover .title {
  background: linear-gradient(0deg, rgba(44,44,44,0.8) 0%, rgba(44,44,44,0.5) 80%, rgba(44,44,44,0) 100%);
}

.card img {
  height:inherit !important;
  min-height:inherit !important;
  max-height:inherit !important;
  transform: scale(1);
  transition:transform 0.5s;
}

.card:hover img {
  transform: scale(1.1);
}

.tag{
  border:1px solid #0077AB;
  border-radius: 2px;
  font-size: 10px;
  color:#0077AB;
  width: 30px;
  text-align: center;
  line-height: 16px;
}

.date{
  font-size: 15px;
  line-height: 25px;
  color:#999999;
  margin-left: 0px;
}

.excerpt{
  font-size: 15px;
  line-height: 20px;
  height: 60px;
  margin-top: 5px;
  color:#666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  padding: 0 12px 0 12px;
  font-weight: 500;
  letter-spacing: 2px;
}

.category{
  background-color: #923F93;
  text-align: center;
  color: #fff;
  line-height: 25px;
  width: 120px;
  float: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding:0 5px 0 5px;
}

.divider{
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px){
  .colum{
    /* padding:0 5px 20px 5px !important; */
    padding:0 0px 15px 0px !important;
    margin:0;
  }

  .card {
    border:none;
  }

  .divider{
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    margin-bottom: 10px;
  }
  
  .infoRow{
    padding:0 0 12px 12px;
  }  
  
  .title{
    font-size: 18px;
    line-height: 30px;
    height: 75px;
    margin-top: -86px;
    padding:10px 10px 20px 10px;
  }
    
  .title:hover{
    background: rgba(95, 95, 95, 0.9);
  }

  .excerpt{
    font-size: 18px;
    line-height: 23px;
    height: 69px;
    margin-top: 8px;
  }
  
  .date{
    font-size: 16px;
    color:#999999 !important;
  }
  
  .img{
    border-radius: 0px;
  }
}


@media screen and (max-width: 900px) {
  .colum{
    padding:0 3px 6px 3px;
    margin:0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) and (max-height: 1366px) {
  .colum{
    padding:0 3px 6px 3px;
    margin:0;
  }
}

@media screen and (min-width: 901px) and (max-width: 1024px){
  .category{
    font-size:14px;
    color: #fff;
    line-height: 23px;
    width: 100px;
    padding:0 5px 0 5px;
  }
}